export const locale = {
  lang: 'ar',
  data: {
    SIDE_NAV_WALLET_HISTORY: {
      BACK: 'رجوع',
      CONING_SOON: 'قريبًا',
      SEND: 'إرسال',
      DEPOSIT: 'إيداع',
      SWAP: 'تبديل',
      TRANSFER: 'نقل',
      INNER_BALANCE: 'الرصيد الداخلي',
      BALANCE: 'الرصيد',
      TRANSACTION_HISTORY: 'سجل المعاملات',
      SEND_TO_WALLET: 'إرسال إلى المحفظة',
      WALLET_BALANCE: 'رصيد المحفظة',
      TRANSFER_INNER_BALANCE: 'نقل إلى الرصيد الداخلي',
      TRANSFER_WALLET: 'نقل إلى المحفظة',
      TYPE:'TYPE',
      FROM:'From',
      TO:'To',
      STATUS:'Status', 
      VALUE:'VALUE',
      DATE:'Date',
      YOU_DONT_HAVE_TRANSACTIONS_YET:'You don\'t have any transactions yet',
      MORE_TRANSACTION:"More transaction",
      SENDING:"Sending",
      RECEIVING:"Receiving",
     
    },
  },
};
