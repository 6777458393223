export const locale = {
  lang: 'id',
  data: {
    SIDE_NAV_WALLET_VALUE: {
      WALLET: 'Dompet',
      TOTAL_BALANCE: 'Saldo Total',
      CONING_SOON: 'Segera Hadir',
      SEND: 'Kirim',
      RECEIVE: 'Terima',
      DEPOSIT: 'Deposit',
      SWAP: 'Tukar',
      TRANSFER: 'Transfer',
      WALLET_BALANCE: 'SALDO DOMPET',
      BALANCE: 'SALDO INTERNAL',
      DISCONECT: 'Putuskan Sambungan',
      CANCEL: 'Batal',
      REPLENISH: 'Isi Ulang',
      SEND_TO_WALLET: 'Kirim ke Dompet',
      CHECK_NAME: 'Terima hadiah ke dompet',
      EXTRA_WALLET: 'DOMPET TAMBAHAN',
      SELL: 'Jual MFS',
      BUY: 'Beli MFS',
      HOLD_MFS:'Tahan MFS',
      TRADE: "Perdagangan MFS",
      GAMES_TOKEN:"Token permainan",
    },
  },
};
