export const locale = {
  lang: 'hi',
  data: {
    SIDE_NAV_WALLET_HISTORY: {
      BACK: 'वापस',
      CONING_SOON: 'जल्द आ रहा है',
      SEND: 'भेजें',
      DEPOSIT: 'जमा करें',
      SWAP: 'स्वैप',
      TRANSFER: 'स्थानांतरण',
      INNER_BALANCE: 'आंतरिक शेष',
      BALANCE: 'शेष',
      TRANSACTION_HISTORY: 'लेन-देन इतिहास',
      SEND_TO_WALLET: 'वॉलेट में भेजें',
      WALLET_BALANCE: 'वॉलेट शेष',
      TRANSFER_INNER_BALANCE: 'आंतरिक शेष में स्थानांतरित करें',
      TRANSFER_WALLET: 'वॉलेट में स्थानांतरित करें',
      TYPE:'प्रकार',
      FROM:'से',
      TO:'को',
      STATUS:'स्थिति', 
      VALUE:'कीमत',
      DATE:'तारीख',
      YOU_DONT_HAVE_TRANSACTIONS_YET:'आपके पास अभी तक कोई लेनदेन नहीं है',
      MORE_TRANSACTION:"अधिक लेन-देन",
      SENDING:"भेजना",
      RECEIVING:"प्राप्त",
    },
  },
};
