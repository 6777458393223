export const locale = {
  lang: 'hi',
  data: {
    SIDE_NAV_WALLET_VALUE: {
      WALLET: 'वॉलेट',
      TOTAL_BALANCE: 'कुल शेष',
      CONING_SOON: 'जल्द आ रहा है',
      SEND: 'भेजें',
      RECEIVE: 'प्राप्त करें',
      DEPOSIT: 'जमा',
      SWAP: 'अदला-बदली',
      TRANSFER: 'स्थानांतरण',
      WALLET_BALANCE: 'वॉलेट बैलेंस',
      BALANCE: 'आंतरिक शेष',
      DISCONECT: 'डिस्कनेक्ट',
      CANCEL: 'रद्द करें',
      REPLENISH: 'पुनः भरें',
      SEND_TO_WALLET: 'वॉलेट में भेजें',
      CHECK_NAME: 'वॉलेट में पुरस्कार प्राप्त करें',
      EXTRA_WALLET: 'अतिरिक्त वॉलेट',
      SELL: 'MFS बेचें',
      BUY: 'MFS खरीदें',
      HOLD_MFS:'पकड़ो  MFS',
      TRADE: "ट्रेड MFS",
      GAMES_TOKEN:"गेम टोकन",
    },
  },
};
