export const locale = {
  lang: 'id',
  data: {
    SIDE_NAV_WALLET_HISTORY: {
      BACK: 'Kembali',
      CONING_SOON: 'Segera hadir',
      SEND: 'Mengirim',
      DEPOSIT: 'Deposito',
      SWAP: 'Menukar',
      TRANSFER: 'Transfer',
      INNER_BALANCE: 'Keseimbangan Batin',
      BALANCE: 'Keseimbangan',
      TRANSACTION_HISTORY: 'RIWAYAT TRANSAKSI',
      SEND_TO_WALLET: 'Kirim ke dompet',
      WALLET_BALANCE: 'Saldo dompet',
      TRANSFER_INNER_BALANCE: 'Transfer ke keseimbangan batin',
      TRANSFER_WALLET: 'Transfer ke dompet',
      TYPE:'JENIS',
      FROM:'Dari',
      TO:'Ke',
      STATUS:'Status', 
      VALUE:'NILAI',
      DATE:'Date',
      YOU_DONT_HAVE_TRANSACTIONS_YET:'Anda belum memiliki transaksi apa pun',
      MORE_TRANSACTION:"Lebih banyak transaksi",
      SENDING:"Mengirim",
      RECEIVING:"Menerima",
    },
  },
};
