<div class="row h-100 m-0">
  <div
    class="col-12 d-flex align-items-center justify-content-between header-menu">
    <div
      class="back-arrow-wallet d-flex align-items-center cursor-pointer"
      (click)="closeSlideNav(false)">
      <div class="icon"></div>
      {{ 'SIDE_NAV_WALLET_HISTORY.BACK' | translate }}
    </div>
    <div class="header-title">
      {{
        item.sideNavType === sideNavType.inner
          ? ('SIDE_NAV_WALLET_HISTORY.INNER_BALANCE' | translate)
          : ('SIDE_NAV_WALLET_HISTORY.WALLET_BALANCE' | translate)
      }}
    </div>
    <img
      class="button-close cursor-pointer button-black"
      (click)="closeALLSlideNav(false)"
      src="/assets/icon/closeButton.svg"
      alt="" />
  </div>

  <div class="col-12 height-container-menu">
    <div class="row m-0">
      <div class="col-12 container-total-balance d-flex justify-content-center">
        <div>
          {{ 'SIDE_NAV_WALLET_HISTORY.BALANCE' | translate }}
          <span>
            {{
              item.name 
            }}
          </span>
        </div>
        <div class="total-balance-container">
          <span class="total-balance-value">
            {{
              +(
                item.sideNavType === sideNavType.inner
                  ? +item.valuePayment
                  : +item.value
              ).toFixed(2)
            }}
          </span>
          <span class="conteiner-coning-soon" *ngIf=" ( item.name != 'NTC' && item.name != 'QRE' && item.name != 'EQN' && item.name != 'RES' ) ">
            <img
              class="total-balance-plus disabled"
              src="/assets/icon/plusOrange.png"
              alt="" />
            <div class="coning-soon coning-soon-right">
              {{ 'SIDE_NAV_WALLET_HISTORY.CONING_SOON' | translate }}
            </div>
          </span>
        </div>
        <div *ngIf=" ( item.name != 'NTC' && item.name != 'QRE' && item.name != 'EQN' && item.name != 'RES' ) "> 
          $
          {{
            +(
              +item.costCoin *
              (item.sideNavType === sideNavType.inner
                ? +item.valuePayment
                : +item.value)
            ).toFixed(1)
          }}
        </div>
      </div>
      <div class="col-12 p-0" *ngIf=" ( item.name != 'NTC' && item.name != 'QRE' && item.name != 'EQN' && item.name != 'RES' ) ">
        <div class="container-button row m-0">
          <div class="conteiner-coning-soon col-4 p-0">
            <div class="coning-soon coning-soon-top">
              {{ 'SIDE_NAV_WALLET_HISTORY.CONING_SOON' | translate }}
            </div>

            <button   disabled class="btn-orange">
              <img
                class="icon-btn-orange"
                src="/assets/icon/sendIcon.png"
                alt="" />
              {{ 'SIDE_NAV_WALLET_HISTORY.SEND' | translate }}
            </button>
          </div>
          <div class="conteiner-coning-soon col-4 p-0">
            <div class="coning-soon coning-soon-top">
              {{ 'SIDE_NAV_WALLET_HISTORY.CONING_SOON' | translate }}
            </div>
            <button disabled class="btn-orange">
              <img
                class="icon-btn-orange"
                src="/assets/icon/depositIcon.png"
                alt="" />
              {{ 'SIDE_NAV_WALLET_HISTORY.DEPOSIT' | translate }}
            </button>
          </div>

          <div class="conteiner-coning-soon col-4 p-0">
            <div *ngIf=" 
              (item.sideNavType === sideNavType.inner && item.name === 'MFS' ) || (item.name != 'DAI' && item.name !== 'MFS')" class="coning-soon coning-soon-top">
              {{ 'SIDE_NAV_WALLET_HISTORY.CONING_SOON' | translate }}
            </div>

            <button 
              [disabled]="
              
              (item.sideNavType === sideNavType.inner && item.name === 'MFS' ) || (item.name != 'DAI' && item.name !== 'MFS') "
              class="btn-orange"
              (click)="openTransfer()">
              <img
                class="icon-btn-orange"
                src="/assets/icon/transferIcon.png"
                alt="" />
              {{ 'SIDE_NAV_WALLET_HISTORY.TRANSFER' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row m-0 scrollable-container media-list" [perfectScrollbar]>
      <div class="col-12 p-0 inner-balace-container">
        <div class="inner-balace-container-header">
          <span>{{
            'SIDE_NAV_WALLET_HISTORY.TRANSACTION_HISTORY' | translate
          }}</span>
        </div>
        <div class="inner-balace-container-body coming-sone-history" *ngIf=" ( item.name != 'NTC' && item.name != 'QRE' && item.name != 'EQN' && item.name != 'RES' ) ">
          <div
            class="d-flex flex-column h-100 justify-content-center align-items-center text-grey">
            <img src="/assets/icon/clock-old-grey.png" />
            <div>Coming soon</div>
          </div>
          <!-- <div class="row inner-balace-container-coin m-0" *ngFor="let coin of historyList; let i = index" [ngClass]="{'border-none': i === historyList.length-1}">
                        <div class="col-8 p-0 d-flex align-items-center">
                            <img  [src]="coin.type === 'wallet'?'/assets/icon/wallet-history-transfer.png' : '/assets/icon/inner-history-transfer.png'">
                         
                            <span class="w-100">
                                <div class="inner-balace-coin-header">
                                    {{coin.type === 'wallet'? ('SIDE_NAV_WALLET_HISTORY.TRANSFER_WALLET' | translate) :('SIDE_NAV_WALLET_HISTORY.TRANSFER_INNER_BALANCE' | translate) }}
                                </div>
                                <div class="inner-balace-coin-description text-grey"
                                    >
                                    {{moment(coin.data).format('DD.MM.YY')}}
                                </div>
                            </span>

                        </div>
                        <div class="col-4 p-0 text-right">
                            <div class="inner-balace-coin-header text-white">
                                {{+(+coin.value).toFixed(2)}} <span> {{item.name === 'MFS' ?  'MFS':item.name === 'DAI' ? 'DAI': 'RES'}} </span> 
                            </div>
                            <div class="inner-balace-coin-description text-grey"
                             >
                                ${{+( +coin.value).toFixed(2)}}
                            </div>

                        </div>

                    </div> -->
        </div>
        <div class="col-12 order-tabel" *ngIf=" ( item.name === 'NTC' || item.name === 'QRE' || item.name === 'EQN' || item.name === 'RES' ) ">
        <div class="col-12 container-list p-0" >
          <div class="d-flex align-items-center justify-content-center spinner-container ">
            <span
            *ngIf="isLoading"
            class="spinner-border spinner-border"
            role="status"
            aria-hidden="true"></span>
          </div>
          <table class="h-100">
            <tr class="header d-flex align-items-center">
              <th class="min-with-hash text-center text-uppercase color-white">
                {{ 'SIDE_NAV_WALLET_HISTORY.TYPE' | translate }}
                </th>
              <th class="min-with-from text-center text-uppercase color-white">
                
                {{ 'SIDE_NAV_WALLET_HISTORY.FROM' | translate }}
              </th>
              <th class="min-with-to text-center text-uppercase color-white">
                
                {{ 'SIDE_NAV_WALLET_HISTORY.TO' | translate }}
              </th>
          
              <th class="min-with-sum  text-center container-sum text-uppercase color-white">
                
                {{ 'SIDE_NAV_WALLET_HISTORY.VALUE' | translate }}
              </th>
              <th class="min-with-date text-center container-date text-uppercase color-white">
                
                {{ 'SIDE_NAV_WALLET_HISTORY.DATE' | translate }}
              </th>
              
            </tr>
           
           
            <tr *ngIf="requestList.length === 0 && !isLoading" class=" d-flex  align-items-center not-item">
              <th colspan="4" class="m-0 text-center not-orders color-white">
                {{ 'SIDE_NAV_WALLET_HISTORY.YOU_DONT_HAVE_TRANSACTIONS_YET' | translate }}
              </th>
            </tr>

            <tr class="container-value d-flex" *ngFor="let item of requestList">
              <th class="min-with-hash value-text text-center color-white p-0">
                 {{item.from === helpInfo.userInfo.value.address? (  'SIDE_NAV_WALLET_HISTORY.SENDING' | translate ):('SIDE_NAV_WALLET_HISTORY.RECEIVING' | translate ) }}
              </th>
              <th class="min-with-from value-text text-center p-0 color-white">
                {{item.from.slice(0, 7) + '...' + item.from.slice(36)}}

              </th>
              <th class="min-with-to text-center color-white">
                {{item.to.slice(0, 7) + '...' + item.to.slice(36)}}
              </th>
      
              <th class="min-with-sum p-0 container-status text-center color-white">
                {{item.value}}
              </th>
              <th class="min-with-date p-0 container-status text-center color-white">
                {{moment(item.date).format('DD.MM.YYYY')}}
              </th>
            </tr>
          </table>
        </div>
        <div class="col-12 d-flex align-items-center justify-content-center padding-top-8px ">
          <button [disabled]="isLoading || isBlockLoding"  class="btn button-grey -br8" (click)="getNewPageHistory()"> {{ 'SIDE_NAV_WALLET_HISTORY.MORE_TRANSACTION' | translate }} </button>
        </div>
      </div>
      </div>
    </div>
  </div>
</div>
