export const locale = {
  lang: 'vi',
  data: {
    SIDE_NAV_WALLET_HISTORY: {
      BACK: 'Quay lại',
      CONING_SOON: 'Sắp ra mắt',
      SEND: 'Gửi',
      DEPOSIT: 'Nạp tiền',
      SWAP: 'Trao đổi',
      TRANSFER: 'Chuyển',
      INNER_BALANCE: 'Số dư nội bộ',
      BALANCE: 'Số dư',
      TRANSACTION_HISTORY: 'LỊCH SỬ GIAO DỊCH',
      SEND_TO_WALLET: 'Gửi vào ví',
      WALLET_BALANCE: 'Số dư ví tiền',
      TRANSFER_INNER_BALANCE: 'Chuyển vào số dư nội bộ',
      TRANSFER_WALLET: 'Chuyển vào ví tiền',
      TYPE:'KIỂU',
      FROM:'Từ',
      TO:'ĐẾN',
      STATUS:'Trạng thái', 
      VALUE:'GIÁ TRỊ',
      DATE:'Ngày',
      YOU_DONT_HAVE_TRANSACTIONS_YET:'Bạn chưa có giao dịch nào',
      MORE_TRANSACTION:"Giao dịch nhiều hơn",
      SENDING:"Gửi",
      RECEIVING:"Nhận được",  
    },
  },
};
