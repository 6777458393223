export const locale = {
  lang: 'ru',
  data: {
    SIDE_NAV_WALLET_HISTORY: {
      BACK: 'Назад',
      CONING_SOON: 'Вскоре',
      SEND: 'Отправить',
      DEPOSIT: 'Депозит',
      SWAP: 'Обмен',
      TRANSFER: 'Перевод',
      INNER_BALANCE: 'Внутренний баланс',
      BALANCE: 'Баланс',
      TRANSACTION_HISTORY: 'ИСТОРИЯ ТРАНЗАКЦИЙ',
      SEND_TO_WALLET: 'Отправить на кошелек',
      WALLET_BALANCE: 'Баланс кошелька',
      TRANSFER_INNER_BALANCE: 'Перевод на внутренний баланс',
      TRANSFER_WALLET: 'Перевод на кошелек',
      TYPE:'Тип',
      FROM:'От',
      TO:'Кому',
      STATUS:'Статус', 
      VALUE:'Сумма',
      DATE:'Дата',
      YOU_DONT_HAVE_TRANSACTIONS_YET:'У вас пока нет транзакций',
      MORE_TRANSACTION:"Больше транзакций",
      SENDING:"Отправка",
      RECEIVING:"Получение",
    },
  },
};
