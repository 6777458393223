export const locale = {
  lang: 'ur',
  data: {
    SIDE_NAV_WALLET_HISTORY: {
      BACK: 'واپس',
      CONING_SOON: 'جلد آرہا ہے',
      SEND: 'بھیجیں',
      DEPOSIT: 'ڈپازٹ',
      SWAP: 'تبادلہ',
      TRANSFER: 'منتقلی',
      INNER_BALANCE: 'اندرونی توازن',
      BALANCE: 'توازن',
      TRANSACTION_HISTORY: 'ٹرانزیکشن کی تاریخ',
      SEND_TO_WALLET: 'بٹوے پر بھیجیں',
      WALLET_BALANCE: 'بٹوے کا توازن',
      TRANSFER_INNER_BALANCE: 'اندرونی توازن میں منتقلی',
      TRANSFER_WALLET: 'بٹوے میں منتقلی',
      TYPE:'قسم',
      FROM:'سے',
      TO:'کرنے کے لئے',
      STATUS:'حیثیت', 
      VALUE:'قدر',
      DATE:'تاریخ',
      YOU_DONT_HAVE_TRANSACTIONS_YET:'آپ کے پاس ابھی تک کوئی لین دین نہیں ہے',
      MORE_TRANSACTION:"مزید لین دین",
      SENDING:"بھیجنا",
      RECEIVING:"وصول کرنا",
    },
  },
};
